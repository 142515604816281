import React, { Component } from "react";
import { connect } from "react-redux";
import { Router, Switch, Route, Redirect } from "react-router-dom";

import Login from "./components/login.component";
import Register from "./components/register.component";
import ActivateSalesAccount from "./components/activateSalesAccount.component";
import ConfirmEmail from "./components/confirmEmail.component";
import Invitation from "./components/invitation.component";

import axios from "axios";
import { v4 as uuidv4 } from 'uuid';

import { logout } from "./actions/auth";
import { history } from './helpers/history';
import { clearMessage } from "./actions/message";

import './App.scss';
import './Modal.scss';
import ExternalSignIn from "./components/externalSignIn.component";
//import 'bootstrap/dist/css/bootstrap.min.css';

// Containers
const Layout = React.lazy(() => import('./containers/layout'));

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

const Terms = React.lazy(() => import('./views/pages/terms/Terms'));
const Privacy = React.lazy(() => import('./views/pages/terms/Privacy'));
const Imprint = React.lazy(() => import('./views/pages/terms/Imprint'));

if (process.env.REACT_APP_BACKEND_URL) {
  axios.defaults.baseURL = window.location.protocol + process.env.REACT_APP_BACKEND_URL;
}

class App extends Component {
  constructor(props) {
    super(props);
    this.logOut = this.logOut.bind(this);

    this.state = {
      currentUser: undefined,
      isTourOpen: window.innerWidth > 992 && !localStorage.getItem('tourIsWatched'),
    };

    history.listen((location) => {
      props.dispatch(clearMessage()); // clear message when changing location
    });
  }

  componentDidMount() {
    const user = this.props.user;

    if (user) {
      this.setState({
        currentUser: user,
      });
    }

    // Set default API call headers
    axios.interceptors.request.use(
      config => {
        config.headers.common['X-Request-Id'] = uuidv4();
        var token = localStorage.getItem('token');
        if (token) {
          config.headers.common['Authorization'] = 'Bearer ' + token;
        }

        return config;
      }
    );

    // impersonation request interceptor
    axios.interceptors.request.use((config) => {
      var impersonating = localStorage.getItem('impersonating') || null;

      if (impersonating !== null) {
        config.params = config.params || {};
        config.params['_switch_user'] = impersonating;
      }
      return config;
    });

    // token expired or invalid token handler
    axios.interceptors.response.use(response => {
      return response;
    }, error => {
      if (
          error.response && error.response.status === 401
          //&& error.response.data.message === 'Expired JWT Token'
      ) {
        if (error.response.data.message === "Authentication request could not be processed due to a system problem.") {
          this.emailIsNotYetConfirmed();
        } else if (error.response.data.message === "Authorization Failed") {
          //this.logOut();
        } else {
          //this.logOut();
        }
      }

      // dirty tmp hack
      if (
          error.response && error.response.status === 403
          && error.response.config.url.includes("billing-api")
          && error.response.config.url.includes("subscriptions\/active")
      ) {
        this.logOut();
      }

      if (
          error.response && error.response.status === 477
      ) {
        this.emailIsNotYetConfirmed();
      }

      return Promise.reject(error);
    });
  }

  logOut() {
    this.props.dispatch(logout());
  }

  emailIsNotYetConfirmed() {
    history.push("/477");
  }

  closeTour = () => {
    this.setState({isTourOpen: false});
    localStorage.setItem('tourIsWatched', true);
  };

  render() {

    return (
        <>
          <Router history={history} basename="/">
            <React.Suspense fallback={loading}>
              <Switch>
                <Route exact path="/confirm-email/:key" name="Confirm Email" render={props => <ConfirmEmail {...props}/>} />
                <Route exact path="/invitation/:key" name="Invitation by Key" render={props => <Invitation {...props}/>} />
                <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>} />
                <Route exact path="/register" name="Register Page" render={props => <Register {...props}/>} />
                <Route exact path="/activate-account" name="Activation page" render={props => <ActivateSalesAccount {...props}/>} />
                <Route exact path="/sign-in/google" name="Sign-In via Google" render={props => <ExternalSignIn {...props}/>} />
                <Route exact path="/terms-and-conditions" name="Terms and conditions" render={props => <Terms {...props}/>} />
                <Route exact path="/privacy-policy" name="Privacy policy" render={props => <Privacy {...props}/>} />
                <Route exact path="/imprint" name="Imprint" render={props => <Imprint {...props}/>} />
                <Route exact path="/checkout-canceled" render={props => <Redirect to="/dashboard" {...props}/>}/>
                <Route exact path="/checkout-succeeded" render={props => <Redirect to="/billing" {...props}/>}/>
                <Route path="/" name="Home" render={props => <Layout {...props}/>} />
              </Switch>
            </React.Suspense>
          </Router>
        </>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.auth;
  return {
    user,
  };
}

export default connect(mapStateToProps)(App)

import React, { Component } from "react";
import queryString from "query-string";
import Form from "react-validation/build/form";
import CheckButton from "react-validation/build/button";
import { Redirect, Link } from 'react-router-dom';
import Fade from 'react-reveal/Fade';

import ReCAPTCHA from "react-google-recaptcha";

import VisibilityOutlined from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlined from '@mui/icons-material/VisibilityOffOutlined';

import FloatingLabel from "react-bootstrap/FloatingLabel";

import Input from "./formControls/input.component";
import { required, isEqual } from "./formControls/validations";

import Tooltip from '@mui/material/Tooltip';

import { connect } from "react-redux";
import { register } from "../actions/auth";

import {
    Card,
    Col,
    Container,
    InputGroup,
    Row
} from 'react-bootstrap'

import { CAPTCHA_PUBLIC_SITE_KEY } from "../services/auth.service"

import { validatePermissions, PERMISSION_LOGGED_OUT }   from "../actions/pipeline";

const recaptchaRef = React.createRef();

class ActivateSalesAccount extends Component {
    constructor(props) {
        super(props);
        this.handleRegister = this.handleRegister.bind(this);
        this.onChangeFirstName = this.onChangeFirstName.bind(this);
        this.onChangeLastName = this.onChangeLastName.bind(this);
        this.onChangeEmail = this.onChangeEmail.bind(this);
        this.onChangeOrganizationName = this.onChangeOrganizationName.bind(this);
        this.onChangePhone = this.onChangePhone.bind(this);
        this.onChangePassword = this.onChangePassword.bind(this);
        this.onChangeConfirmPassword = this.onChangeConfirmPassword.bind(this);
        this.onChangeTermsAndConditions = this.onChangeTermsAndConditions.bind(this);
        this.onChangeNewsAndUpdates = this.onChangeNewsAndUpdates.bind(this);

        this.state = {
            firstName: "",
            lastName: "",
            phone: "",
            email: "",
            password: "",
            passwordType: "password",
            confirmPassword: "",
            confirmPasswordType: "password",
            organizationName: "",
            termsAndConditions: "",
            newsAndUpdates: false,
            successful: false,
            loading: false,
        };
    }

    componentDidMount = async() => {
        document.title = 'Activate account';

        const parsedQsData = queryString.parse(window.location.search);
        let firstName = parsedQsData.first_name || "";
        let lastName = parsedQsData.last_name || "";
        let email = parsedQsData.email || "";
        let organizationName = parsedQsData.organization_name || "";

        await this.setState({ 
            firstName,
            lastName,
            email,
            organizationName,
        });
    };

    onChangeFirstName(e) {
        this.setState({
            firstName: e.target.value,
        });
    }

    onChangeLastName(e) {
        this.setState({
            lastName: e.target.value,
        });
    }

    onChangeEmail(e) {
        this.setState({
            email: e.target.value,
        });
    }

    onChangeOrganizationName(e) {
        this.setState({
            organizationName: e.target.value,
        });
    }

    onChangePhone(e) {
        this.setState({
            phone: e.target.value,
        });
    }

    onChangePassword(e) {
        this.setState({
            password: e.target.value,
        });
    }

    onChangeConfirmPassword(e) {
        this.setState({
            confirmPassword: e.target.value,
        });
    }

    onChangeTermsAndConditions(e) {
        this.setState({
            termsAndConditions: e.target.value,
        });
    }

    onChangeNewsAndUpdates(e) {
        this.setState({
            newsAndUpdates: e.target.checked,
        });
    }

    handleEyeClick = () => this.setState(({passwordType}) => ({
        passwordType: passwordType === 'text' ? 'password' : 'text'
    }));

    handleConfirmEyeClick = () => this.setState(({confirmPasswordType}) => ({
        confirmPasswordType: confirmPasswordType === 'text' ? 'password' : 'text'
    }));

    handleRegister = async(e) => {
        e.preventDefault();

        this.setState({
            successful: false,
            loading: true,
        });

        this.form.validateAll();

        if (this.checkBtn.context._errors.length === 0) {
            await recaptchaRef.current.reset();
            const captchaToken = await recaptchaRef.current.executeAsync();

            this.props
                .dispatch(
                    register( 
                        captchaToken,
                        this.state.firstName,
                        this.state.lastName,
                        this.state.email, 
                        this.state.password, 
                        this.state.confirmPassword,
                        this.state.phone, 
                        this.state.organizationName,
                        null
                    )
                )
                .then(() => {
                    this.setState({
                        successful: true,
                        loading: false,
                    });
                    setTimeout(() => {
                        this.props.history.push('/login')
                    },2000);
                })
                .catch(() => {
                    recaptchaRef.current.reset();
                    this.setState({
                        successful: false,
                        loading: false,
                    });
                });
        } else {
            this.setState({
                loading: false,
            });
        }
    }

    render() {
        const { isLoggedIn, message, user } = this.props;

        if (
            !validatePermissions(isLoggedIn, user, PERMISSION_LOGGED_OUT)
        ) {
            return <Redirect to="/dashboard" />;
        }

        // TMP for the Closed Beta. TODO remove afterward
        /*if (process.env.REACT_APP_ENVIRONMENT === "prod") {
            return <Redirect to="/login" />;
        }*/

        return (
        <div className="align-items-center visualBg">
            <Fade>
                <Link to="/" className="floatingLogo">
                    <img src="/images/logo2.png" width="150px" alt="Datamin"/>
                </Link>
                <Container>
                    <Row className="justify-content-center pt-5">
                        <Col md="9" lg="7" xl="6">
                            <h2 className="alternative text-center mb-3">
                                {this.state.firstName !== ""
                                    ? "Welcome to Datamin, " + this.state.firstName + "!"
                                    : "Welcome to Datamin!"
                                }
                            </h2>
                            <h4 className="text-center mb-3">To activate your account you just need to fill in and confirm some data below:</h4>
                            <Card className="onboardingCard mb-5">
                                <Card.Body className="p-4">
                                    <Form
                                        onSubmit={this.handleRegister}
                                        ref={(c) => {
                                            this.form = c;
                                        }}
                                    >
                                        <ReCAPTCHA
                                            ref={recaptchaRef}
                                            size="invisible"
                                            sitekey={CAPTCHA_PUBLIC_SITE_KEY}
                                        />
                                        <InputGroup className="mb-4">
                                            <div className="registrationFormControl">
                                            <FloatingLabel controlId="floatingOrganizationName" label="Organization name">
                                            <Input
                                                className="form-control form-control-lg"
                                                type="text"
                                                id="floatingOrganizationName"
                                                placeholder="Organization name"
                                                autoComplete="organizationName"
                                                name="organizationName"
                                                value={this.state.organizationName}
                                                onChange={this.onChangeOrganizationName}
                                                autoFocus
                                                validations={[required]}
                                            />
                                            </FloatingLabel>
                                            </div>
                                        </InputGroup>
                                        <InputGroup className="mb-4">
                                            <div className="registrationFormControl">
                                            <FloatingLabel controlId="floatingFirstName" label="First name">
                                            <Input
                                                className="form-control form-control-lg"
                                                type="text"
                                                id="floatingFirstName"
                                                placeholder="First name"
                                                autoComplete="firstName"
                                                name="firstName"
                                                value={this.state.firstName}
                                                onChange={this.onChangeFirstName}
                                                validations={[required]}
                                            />
                                            </FloatingLabel>
                                            </div>
                                        </InputGroup>
                                        <InputGroup className="mb-4">
                                            <div className="registrationFormControl">
                                            <FloatingLabel controlId="floatingLastName" label="Last name">
                                            <Input
                                                className="form-control form-control-lg"
                                                type="text"
                                                id="floatingLastName"
                                                placeholder="Last name"
                                                autoComplete="lastName"
                                                name="lastName"
                                                value={this.state.lastName}
                                                onChange={this.onChangeLastName}
                                                validations={[required]}
                                            />
                                            </FloatingLabel>
                                            </div>
                                        </InputGroup>
                                        <InputGroup className="mb-4">
                                            <div className="registrationFormControl">
                                            <FloatingLabel controlId="floatingOrganizationEmail" label="Email">
                                            <Input
                                                type="text"
                                                placeholder="Email"
                                                id="floatingOrganizationEmail"
                                                autoComplete="email"
                                                className="form-control form-control-lg"
                                                name="email"
                                                value={this.state.email}
                                                onChange={this.onChangeEmail}
                                                validations={[required]}
                                            />
                                            </FloatingLabel>
                                            </div>
                                        </InputGroup>
                                        <InputGroup className="mb-4">
                                            <div className="registrationFormControl">
                                            <FloatingLabel controlId="floatingPassword" label="Password">
                                            <Input
                                                type={this.state.passwordType}
                                                id="floatingPassword"
                                                placeholder="Password"
                                                autoComplete="new-password"
                                                className="form-control form-control-lg"
                                                name="password"
                                                value={this.state.password}
                                                onChange={this.onChangePassword}
                                                validations={[required, isEqual]}
                                            />
                                            </FloatingLabel>
                                            </div>
                                            <span
                                                onClick={this.handleEyeClick}
                                                className="eye"
                                            >
                                                {
                                                    this.state.passwordType === 'text' 
                                                    ? <Tooltip title="Hide" placement="right"><VisibilityOffOutlined/></Tooltip>
                                                     : <Tooltip title="Show" placement="right"><VisibilityOutlined/></Tooltip>
                                                 }
                                            </span>
                                        </InputGroup>
                                        <InputGroup className="mb-4">
                                            <div className="registrationFormControl">
                                            <FloatingLabel controlId="floatingConfirmPassword" label="Confirm password">
                                            <Input
                                                type={this.state.confirmPasswordType}
                                                placeholder="Confirm password"
                                                id="floatingConfirmPassword"
                                                autoComplete="confirm-password"
                                                className="form-control form-control-lg"
                                                name="confirmPassword"
                                                value={this.state.confirmPassword}
                                                onChange={this.onChangeConfirmPassword}
                                                validations={[required, isEqual]}
                                            />
                                            </FloatingLabel>
                                            </div>
                                            <span
                                                onClick={this.handleConfirmEyeClick}
                                                className="eye"
                                            >
                                                {
                                                    this.state.confirmPasswordType === 'text' 
                                                    ? <Tooltip title="Hide" placement="right"><VisibilityOffOutlined/></Tooltip>
                                                     : <Tooltip title="Show" placement="right"><VisibilityOutlined/></Tooltip>
                                                }
                                            </span>
                                        </InputGroup>
                                        <InputGroup className="mb-1">
                                            <div className="input-group-radio">
                                                <Input
                                                    type="checkbox"
                                                    placeholder="Terms and conditions"
                                                    autoComplete="Terms and conditions"
                                                    className="form-control-checkbox"
                                                    name="termsAndConditions"
                                                    value="1"
                                                    onChange={this.onChangeTermsAndConditions}
                                                    validations={[required]}
                                                />
                                            </div>
                                            <label className="labelToCheckbox">
                                                I agree to <Link to="/terms-and-conditions" target="_blank">Terms</Link> and <Link to="/privacy-policy" target="_blank">Privacy Policy</Link>.
                                            </label>
                                        </InputGroup>
                                        <Row className="pt-3">
                                            <Col xs="12">
                                                <button
                                                    className="px-4 btn btn-primary"
                                                    disabled={this.state.loading}
                                                    type="submit"
                                                >
                                                    {this.state.loading && (
                                                        <span className="spinner-border spinner-border-sm spinner-primary"></span>
                                                    )}
                                                    <span>Activate my account</span>
                                                </button>
                                            </Col>
                                        </Row>
                                        {message && (
                                            <div className="form-group">
                                                <div className={ this.state.successful ? "alert alert-success mt-3" : "alert alert-danger mt-3" } role="alert">
                                                    {message}
                                                </div>
                                            </div>
                                        )}
                                        <CheckButton
                                            style={{ display: "none" }}
                                            ref={(c) => {
                                                this.checkBtn = c;
                                            }}
                                        />
                                    </Form>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Fade>
        </div>
        );
    }
}

function mapStateToProps(state) {
    const { isLoggedIn } = state.auth;
    const { user } = state.auth;
    const { message } = state.message;
    return {
        isLoggedIn,
        message,
        user,
    };
}

export default connect(mapStateToProps)(ActivateSalesAccount);

import GA4React from "ga-4-react";
import { createBrowserHistory } from "history";

export const history = createBrowserHistory();

export const impersonating = localStorage.getItem('impersonating');

if (process.env.NODE_ENV === "prod" && !impersonating) {
    try {
        setTimeout(_ => {
            const ga4react = new GA4React("G-CLLR990KXV");
            ga4react.initialize().catch(err => console.error(err));
        }, 4000);
    } catch (err) {
        console.error(err);
    }

    history.listen((location, action) => {
        //ReactGA.send("pageview");
    });
}
